import styled from '@emotion/styled';
import { colors, dimensions } from '../../styles/variables';
import pricingArrow from '../../assets/pricing-arrow.svg';

interface SectionProps {
  isVisible?: boolean;
}

export const SafariWrapper = styled.div`
  width: 100%;
  overflow: hidden;
`;

export const Section = styled.section<SectionProps>`
  
  margin-top: 115px;

  /*
  opacity: 0;
  transform: translateY(10%);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;

  ${({ isVisible }) =>
    isVisible &&
    `
      opacity: 1;
      transform: translateY(0);
    `}*/
`;

export const Heading = styled.header`
  display: flex;
  width: 100%;
  max-width: 924px;
  margin: 0 auto 0 auto;
  position: relative;
  justify-content: center;


  ${({ withTitle }) =>
  !withTitle &&
  `
    height: 108px;
`}

  &:before,
  &:after {
    content: '';
    display: block;
    width: 122px;
    height: 130px;
    position: absolute;
    bottom: 0;
    z-index: 4;
    background: url(${pricingArrow}) no-repeat 0 0;
    background-size: contain;
  }

  &:before {
    transform: translate(-231px, -128px);
  }
  &:after {
    transform: scale(-1, 1) translate(-228px, -128px);
  }
`;

export const Title = styled.h2`
  margin: 0;
  font-weight: 900;
  font-size: ${dimensions.fontSize.heading}px;
  line-height: ${dimensions.lineHeight.heading};
  color: ${colors.darkNavyBlue};
  
  text-align: center;
`;
