import React, { ReactElement, useRef } from 'react';
import * as Styled from './styles';
// import { SimplePricing } from '../Pricing/SimplePricing';
import { useVisibilityDetection } from '../../hooks/useVisibilityDetection';
import Divider from '../../assets/divider.inline.svg';
import { css } from '@emotion/react';
import { breakpoints } from '../../styles/variables';


interface DealPricingProps {
  withTitle: boolean;
}

export const DealPricing = ({ withTitle=true }: DealPricingProps): ReactElement => {
  const sectionRef = useRef(null);
  const { isVisible } = useVisibilityDetection(sectionRef);
  
  return (
    <Styled.SafariWrapper id="DealPricing">
      <Styled.Section isVisible={isVisible} ref={sectionRef}>

        
          <Styled.Heading withTitle={withTitle}>

          {withTitle &&
            <Styled.Title>Time To Ditch The Dull Diagrams & Flowcharts...</Styled.Title>
             }

          </Styled.Heading>
       
      </Styled.Section>
    </Styled.SafariWrapper>
  );
};
