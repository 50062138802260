import React from 'react';

export const PRICING_DATA = [
  {
    name: 'Standard',
    price: {
      monthly: {
        amount: 12,
        url: '#',
      },
      annual: {
        amount: 10,
        url: '#',
      },
    },
    features: [
      
      {
        type: 'contains',
        description: 'Up to 10 pages per document',
      },
      {
        type: 'contains',
        description: 'Unlimited Sketches',
      },
      {
        type: 'contains',
        description: 'Limited templates',
      },
      {
        type: 'contains',
        description: '238 elements, shapes, icons',
      },
      {
        type: 'contains',
        description: 'Standard color backgrounds',
      },
      {
        type: 'contains',
        description: '15 fonts (+ import custom fonts)',
      },
      {
        type: 'contains',
        description: 'Resize canvas (Custom size)',
      },
      {
        type: 'contains',
        description: 'Import Image (Drag-and-drop)',
      },
      {
        type: 'contains',
        description: 'Import Image (Drag-and-drop)',
      },
      {
        type: 'contains',
        description: 'JPG, PNG, SVG, PDF, Animated GIF (Export)',
      },
      {
        type: 'contains',
        description: 'Presentation mode',
      },
      {
        type: 'contains',
        description: 'File backup & restore',
      },
      {
        type: 'contains',
        description: 'Use on multiple computers',
      },
      {
        type: 'contains',
        description: 'Standard support',
      },
      {
        type: 'excludes',
        description: 'Get access to new shapes, images & templates',
      },
    ],
  },
  {
    name: 'Pro',
    price: {
      monthly: {
        amount: 24,
        url: '#',
      },
      annual: {
        amount: 20,
        url: '#',
      },
    },
    features: [
      {
        type: 'contains',
        description: (
          <>
            Everything <strong>Standard</strong> has, plus:
          </>
        ),
      },
      {
        type: 'additional',
        description: (
          <>
            <strong>Unlimited</strong> elements per document
          </>
        ),
      },
      {
        type: 'additional',
        description: (
          <>
            <strong>Unlimited</strong> pages per document
          </>
        ),
      },
      {
        type: 'additional',
        description: (
          <>
            <strong>Unlimited</strong> editable documents
          </>
        ),
      },
      {
        type: 'additional',
        description: (
          <>
            <strong>50+</strong> premium templates
          </>
        ),
      },
      {
        type: 'additional',
        description: (
          <>
            <strong>500+</strong> elements, shapes, icons
          </>
        ),
      },
      {
        type: 'additional',
        description: (
          <>
            <strong>10</strong> exclusive background images
          </>
        ),
      },
      {
        type: 'additional',
        description: (
          <>
            <strong>30</strong> Fonts (+ import custom fonts)
          </>
        ),
      },
      {
        type: 'additional',
        description: 'Resize canvas (Custom size)',
      },
      {
        type: 'contains',
        description: 'Add any image (Drag-and-drop)',
      },
      {
        type: 'contains',
        description: 'JPG, PNG, SVG, PDF, Animated GIF (Export)',
      },
      {
        type: 'contains',
        description: 'Presentation mode',
      },
      {
        type: 'contains',
        description: 'File backup & restore',
      },
      {
        type: 'contains',
        description: 'Use on multiple computers',
      },
      {
        type: 'contains',
        description: 'Premium support',
      },
      {
        type: 'additional',
        description: 'Get access to new shapes, images & templates',
      },
    ],
  },
];


export const PRICING_DATA_LTD = [
  {
    pro: '$97',
    prom: '$69',
    proa: '$480',
    std: '$49',
    stdm: '$39',
    stda: '$240'
  }
];